import config from '@/config';
import apiErrorsConstants from '@/constants/apiErrors.constants';

import httpService from '@/services/http.service';
import apiErrorUtils from '@/utils/apiErrors.utils';

const BASE_URL = config.lowcoApiUrl;

const mapError = (error) => {
  switch (error.code) {
    case apiErrorsConstants.CUSTOMER_ALREADY_EXISTS:
      return apiErrorsConstants.CUSTOMER_ALREADY_EXISTS;
    default:
      return 'errorMessages.general';
  }
};

const throwError = apiErrorUtils.makeThrowError(mapError);

const searchCustomers = (firstname, lastname, email, postalCode) =>
  httpService
    .get(
      `${BASE_URL}/customers?firstname=${firstname}&lastname=${lastname}&email=${email}&postalCode=${postalCode}`,
    )
    .catch(throwError);

const searchLegacyCustomers = (companyId, firstname, lastname, email) =>
  httpService
    .get(
      `${BASE_URL}/companies/${companyId}/legacy-customers?firstname=${firstname}&lastname=${lastname}&email=${email}`,
    )
    .catch(throwError);

const fetchCustomer = (customerId) => httpService
    .get(`${BASE_URL}/customers/${customerId}`)
    .catch(throwError);

const fetchLoyaltyCustomers = (companyId, pageNumber = 1, pageSize = 10) => httpService
  .get(`${BASE_URL}/companies/${companyId}/loyalty-customers?pageNumber=${pageNumber}&pageSize=${pageSize}`, null, { paginated: true })
  .catch(throwError);

const inviteCustomer = (companyId, data, legacyCustomerId = null) => {
  let url = `${BASE_URL}/companies/${companyId}/customers`;

  if (legacyCustomerId) {
    url = `${url}?legacyCustomerId=${legacyCustomerId}`;
  }
  
  return httpService
    .post(url, data)
    .catch(throwError);
}

export default {
  searchCustomers,
  fetchCustomer,
  searchLegacyCustomers,
  fetchLoyaltyCustomers,
  inviteCustomer,
};
