<script setup>
import QrcodeVue from 'qrcode.vue';
import { watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import Button from '@/components/common/Button.vue';
import customersService from '@/services/customers.services';

const customer = ref();
const { t } = useI18n();
const { query } = useRoute();
const { customerId } = query;

const handlePrint = () => {
  window.print();
};

const handleBackClick = () => {
  window.close();
};

watch(() => query.customerId, async (customerIdValue) => {
  if (!customerIdValue) {
    return;
  }

  customer.value = await customersService.fetchCustomer(customerIdValue);

  setTimeout(handlePrint, 300);
}, { immediate: true });
</script>

<template>
  <div :class="$style.qrcode">
    <img :class="$style.logo" src="@/assets/images/logo-green.png" alt="">
    <p :class="$style.name">{{ `${customer.firstName} ${customer.lastName}` }}</p>
    
    <QrcodeVue v-if="customerId" :value="customerId" :size="300" />

    <div :class="$style.buttons">
      <Button :class="$style.button" block @buttonClick="handleBackClick">
        {{ t('pages.printClientQrCode.back') }}
      </Button>
      <Button :class="$style.button" block @buttonClick="handlePrint">
        {{ t('pages.printClientQrCode.print') }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@/assets/styles/common/mixins';
  
.logo {
  max-width: 30rem;
  width: 90%;
}
  
.name {
  font-size: 2rem;

  margin-top: 2rem;
  margin-bottom: 4rem;
}

.qrcode {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttons {
  min-width: 30rem;
  margin-top: 2rem;

  display: flex;

  @include spacing-children("horizontal", 1rem);
  
  @media print {
    display: none;
  }
}

.button {
  flex: 1;
}
</style>